#sidebar-wrapper {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  min-height: 100vh !important;
  z-index: 100;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.no-style-link {
  text-decoration: none;
}

#page-content-wrapper {
  width: 100%;
}
