.settings {
  button.action-button {
    background: none;
    border-radius: 5px;
    &:hover {
      background: white;
    }
  }
  .svg-inline--fa.actionButton {
    color: #0d6efd;
  }
  .userSettings {
    table th {
      cursor: pointer;
    }
  }
}
