.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.logoContainer{
  width: 60%;
  height: 100%;
  background-color: #28293e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  color: #fff;
  img {
    width: 156px;
    height: 27px;
  }
  h1 {
    font-weight: 800;
    text-transform: capitalize;
    font-size: 84px;
  }
}

.formContainer {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0ff;
}

.form {
  width: 320px;
}
.tab {
  font-weight: 400 !important;
  font-size: 16px !important;
  width: 50%;
  :global{
    .Mui-selected{
      color: #2137f9 !important;
    }
  }
}
.selected {
  color: #2137f9 !important;
}

.greeting {
  font-weight: 500 !important;
  text-align: center !important;
  margin-top: 30px !important;
  font-size: 3rem !important;
  color: #4a4a4a;
}
.subGreeting {
  font-weight: 500 !important;
  text-align: center !important;
  margin-top: 15px !important;
  font-size: 2rem !important;
  color: #4a4a4a;
}
.errorMessage {
  text-align: center;
}
.textFieldUnderline {
  &:hover:before {
    border-bottom-color: #1976d2 !important;
  }
}
.textField {
  border-bottom-color: #8058ce !important;
}
.formButtons {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .submitButton {
    background-color: #2137f9 !important;
  }
}
.loginLoader {
  margin-left: 30px;
}
.forgetButton {
  text-transform: none;
  font-weight: 400;
  color: #2137f9 !important;
}
.creatingButtonContainer {
  margin-top: 20px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.createAccountButton {
  height: 46px;
  text-transform: none;
  background-color: #2137f9 !important;
}

@media screen and (max-width: 1280px) {
  .logoContainer {
    display: none;
  }
  .formContainer{
    width: 100%;
  }
}