.pageContent {
  .pageContent__title {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    ol {
      margin: 0;
    }
    p,
    li {
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      margin-right: 1rem;
      font-size: 1.25rem;
      text-decoration: none;
      white-space: nowrap;
      margin: 0;
    }
  }
}
