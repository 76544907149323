.leftSidebar {
  max-height: 100vh;
  position: sticky;
  top: 0;
  .mainMenu {
    .nav-item {
      width: 100%;
    }
    a.active {
      background: white;
      color: black !important;
    }
  }
  .addAccounDropdown {
    max-width: 250px;
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
