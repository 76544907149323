.leads {
  input.searchInput {
    padding-left: 35px;
    background: url('../../assets/img/search.svg');
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 11px 11px;
  }
  .leads_table {
    &__row {
      cursor: pointer;
    }
  }
  .loadMoreButton {
  }
  .warningText {
    font-size: 14px;
  }
}
